import React, { useState, useEffect, useMemo } from "react";
import M from "materialize-css";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";
import { convertDeliveryDay } from "../../helpers";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
// Component Function
const OrderList = (props) => {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalRecords: 0,
    totalPages: 0,
  });

  const history = useHistory();
  const [isDeleteLaoded, setIsDeleteLaoded] = useState(true);
  const [isAllOrdersLoding, setIsAllOrdersLoding] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const query = new URLSearchParams(history.location.search);
  const status = query.get("status");
  const startDate = query.get("startDate");
  const [filterFormData, setFilterFormData] = useState({
    orderStatus: "All",
  });
  const [useFilter, setUseFilter] = useState(false);

  useEffect(() => {
    if (startDate == "new") {
      const today = date.format(new Date(), "YYYY-MM-DD");
      setFilterFormData({ ...filterFormData, startDate: today });
      setUseFilter(!useFilter);
    } else {
      setFilterFormData({ ...filterFormData, startDate: "" });
      setUseFilter(!useFilter);
    }
  }, [startDate]);
  // Delete Submit Handler
  const deleteSubmitHandler = () => {
    setIsDeleted(false);
    setIsDeleteLaoded(false);

    fetch(`${Config.SERVER_URL}/adminOrders/${deleteId}`, {
      method: "DELETE",
      // body: JSON.stringify({deleteId}),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsDeleteLaoded(true);
          if (result.status == 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            setIsDeleted(true);
            setDeleteId("");
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          $("#closeDeleteModalButton").click();
        },
        (error) => {
          setIsDeleteLaoded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Get Data From Database
  useEffect(() => {
    setIsAllOrdersLoding(true);
    let url = `${Config.SERVER_URL}/adminOrders?page=${pagination.page}&limit=${pagination.limit}`;
    if (status && status !== "ALL") {
      url = url + `&orderStatus=${status}`;
    }

    if (filterFormData.startDate)
      url = url + `&startDate=${filterFormData.startDate}`;
    if (filterFormData.endDate)
      url = url + `&endDate=${filterFormData.endDate}`;

    console.log(url);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setAllOrders(result.body || []);
            setPagination({
              ...pagination,
              page: result.page,
              totalPages: result.totalPages,
              totalRecords: result.totalRecords,
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAllOrdersLoding(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsAllOrdersLoding(false);
        }
      );
  }, [pagination.page, pagination.limit, isDeleted, useFilter, status]);

  const columns = useMemo(
    () => [
      {
        Header: "SN",
        accessor: "sn",
      },
      {
        Header: "CUSTOMER DETAILS",
        accessor: "customer",
        Cell: ({ value: customer }) => {
          return (
            <>
              <p>{customer?.firstName}</p>
              <p>
                <a href={`mailto:${customer?.email}`}>{customer?.email}</a>
              </p>
              <p>
                <a href={`tel:${customer?.mobile}`}>{customer?.mobile}</a>
              </p>
            </>
          );
        },
      },
      {
        Header: "PRODUCTS",
        accessor: "products",
        Cell: ({ value }) => {
          return (
            <>
              {`${value[0]?.name}  ${
                value?.length > 1 ? "+" + value?.length - 1 + " Items" : ""
              }`}
            </>
          );
        },
      },
      {
        Header: "ORDER AMOUNT",
        accessor: "orderAmount",
        Cell: ({ value }) => {
          return (
            <>
              <i className="fa fa-inr"></i>
              {value}
            </>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <>
              {value === "ORDERPLACED" ? (
                <span className="badge badge-primary">{value}</span>
              ) : value === "CONFIRMED" ? (
                <span className="badge badge-info">{value}</span>
              ) : value === "DELIVERED" ? (
                <span className="badge badge-success">{value}</span>
              ) : value === "CANCELLED" ? (
                <span className="badge badge-danger">{value}</span>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "ORDER DATE",
        accessor: "createdAt",
        Cell: ({ value }) => {
          return date.format(new Date(value), "DD-MM-YYYY");
        },
      },

      {
        Header: "ACTION",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <>
              <Link
                className="ml-2 btn btn-info footable-edit rounded"
                to={{
                  pathname: `/admin/order/details/${value}`,
                }}
              >
                <span className="fas fa-eye" aria-hidden="true"></span>
              </Link>
              <button
                type="button"
                className="ml-2 btn btn-danger footable-delete rounded"
                data-toggle="modal"
                data-target="#deleteModal"
                onClick={(e) => {
                  setDeleteId(value);
                }}
              >
                <span className="fas fa-trash-alt" aria-hidden="true"></span>
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return allOrders.map((data, index) => {
      return {
        sn: ++index,
        customer: data.customer,
        products: data?.products,
        orderAmount: data?.totalAmount,
        createdAt: data.createdAt,
        status: data.orderStatus,
        action: data._id,
      };
    });
  }, [allOrders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFilters, useSortBy, usePagination);

  // Return function
  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"ORDERS"} pageTitle={"Odrer Lists"} />

        {/* End Bread crumb and right sidebar toggle */}
        <div
          className={"row page-titles px-1 my-0 shadow-none"}
          style={{ background: "none" }}
        >
          <div className={"col-md-12 px-0"}>
            {/* Heading */}
            <div className={"card mb-0 mt-2 border-0 rounded"}>
              <div className={"card-body pb-0 pt-2"}>
                <div className="d-flex justify-content-between">
                  <div className="form-inline d-flex" style={{ gap: "10px" }}>
                    <select
                      className="form-control shadow-sm rounded"
                      onChange={(evt) => {
                        setFilterFormData({
                          ...filterFormData,
                          orderStatus: evt.target.value,
                        });
                        history.push(
                          "/admin/orders?status=" + evt.target.value
                        );
                      }}
                      value={filterFormData.orderStatus}
                    >
                      <option value="ALL">ALL</option>
                      <option value="ORDERPLACED">ORDERPLACED</option>
                      <option value="CONFIRMED">CONFIRMED</option>
                      <option value="CANCELLED">CANCELLED</option>
                      <option value="DELIVERED">DELIVERED</option>
                    </select>

                    <input
                      type="date"
                      placeholder="Start Date"
                      className="form-control bg-light p-1"
                      onChange={(event) => {
                        setFilterFormData({
                          ...filterFormData,
                          startDate: event.target.value,
                        });
                      }}
                      value={filterFormData.startDate}
                    />
                    <input
                      type="date"
                      className="form-control bg-light p-3"
                      onChange={(event) => {
                        setFilterFormData({
                          ...filterFormData,
                          endDate: event.target.value,
                        });
                      }}
                      value={filterFormData.endDate}
                    />

                    <button
                      className="btn btn-info"
                      onClick={() => {
                        setUseFilter(!useFilter);
                      }}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Data */}
            {!isAllOrdersLoding ? (
              <div className="card border-0 rounded m-0 py-1">
                {allOrders.length ? (
                  <div className="card-body py-0">
                    <div className="table-responsive">
                      {/* Data Tables */}
                      <DataTable
                        getTableBodyProps={getTableBodyProps}
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        rows={rows}
                        prepareRow={prepareRow}
                      />

                      {/* Pagination */}
                      <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        tableName={"table-to-xls"}
                        csvFileName={"orders"}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={"alert alert-danger mx-3 rounded border-0 py-2"}
                  >
                    No Data Available
                  </div>
                )}
              </div>
            ) : (
              <div className={"bg-white p-3 text-center"}>
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading..
              </div>
            )}
          </div>

          {/* -- Delete Modal -- */}
          <div
            className="modal fade rounded"
            id="deleteModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="updateModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content rounded">
                <div className="modal-body text-center">
                  <img
                    style={{ width: "150px" }}
                    className={"img img-fluid"}
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ5R1g82DqzH4itsxpVCofNGWbAzKN_PJDBew&usqp=CAU"
                    }
                  />
                  <h4 className={"text-center mt-2"}>Do You Want to Delete?</h4>

                  <div className={"form-group"}>
                    <button
                      className="btn btn-danger rounded px-3"
                      type={"submit"}
                      onClick={deleteSubmitHandler}
                    >
                      {isDeleteLaoded ? (
                        <div>
                          <i className="fas fa-trash"></i> Yes
                        </div>
                      ) : (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading..
                        </div>
                      )}
                    </button>
                    <button
                      className="btn btn-secondary rounded ml-2 px-3"
                      data-dismiss="modal"
                      id={"closeDeleteModalButton"}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
